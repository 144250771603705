import React from 'react'
import { motion } from 'framer-motion'

import './footer.css'

export const Footer = () => {
  return (
    <motion.div className="footer-container"
    initial={{y: 100}}
    animate={{y: 0}}
    transition={{duration:1}}
    >
    </motion.div>
  )
}
