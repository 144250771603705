import React from 'react'
import { useState } from 'react'
import { motion } from 'framer-motion'

import './navbar.css'
import { MenuModal } from '../menuModal/MenuModal'
import { AnimatePresence } from 'framer-motion'


export const Navbar = (props) => {
  const [navClick, setNavClick] = useState(false)

  const handleClick = () => {
    setNavClick(!navClick)
  }



  return (
    <>
    <motion.div className="navbar-container"
    initial={{y: -100}}
    animate={{y: 0}}
    transition={{duration:.3, delay: .5}}
    >
      <AnimatePresence mode='wait'>
        <motion.p className={ navClick ? 'nav-text-nav' : 'nav-text'} 
        onClick={() => {setNavClick(false)}}
        initial={{opacity: 0, y: -15}}
        animate={{opacity:1, y: 0}}
        transition={{duration: .4, delay: 0 }}
        exit={{opacity: 0, y:15}}
        key={props.location.pathname + navClick}
        > 
          {navClick ? '/nav' : props.location.pathname}
          {props.location.pathname === '/' && !navClick ? 'home' : null}
        </motion.p>
      </AnimatePresence>

      <AnimatePresence mode='wait'>
        <motion.p
         initial={{opacity: 0}}
         animate={{opacity:0}}
         exit={{opacity:0}}
         transition={{duration: 0}}
         key={navClick}
         className={ !navClick ? 'open-menu-btn' : 'open-menu-btn-active'} 
         onClick={() => {handleClick()}}>{navClick ? 'Menu Open' : 'Open Menu'}
        </motion.p>
      </AnimatePresence>
    </motion.div>
    <AnimatePresence mode='wait'>
      {navClick ? <MenuModal key={'menu-modal'} isOpen={setNavClick} /> : ''}
    </AnimatePresence>
    </>
  )
}
