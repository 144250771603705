import React from 'react'
import { Link } from 'react-router-dom'

import './socialsBox.css'

import InstagramIcon from '../../assets/icons/instagramIcon.png'
import MusicIcon from '../../assets/icons/appleMusic.png'
import DiscordIcon from '../../assets/icons/discordIcon.png'
import GithubIcon from '../../assets/icons/githubIcon.png'

export const SocialsBox = () => {
  return (
    <div className='socials-box-container'>
        <h1>Find me on:</h1>
        <div className="socials-box-grid">
            <Link className="socials-grid-item" to={'https://www.instagram.com/camiloleech/'} target="_blank">
                <img src={InstagramIcon} alt="instagram-icon" />
                <p>Insta</p>
            </Link>
            <Link className="socials-grid-item" to={'https://music.apple.com/us/playlist/dish-washer/pl.u-d2b0kAXCDZYLA5'} target='_blank'>
                <img src={MusicIcon} alt="youtube-icon" />
                <p>Playlist</p>
            </Link>
            <Link className="socials-grid-item" to={'https://www.discordapp.com/users/505198311582466050'} target="_blank">
                <img src={DiscordIcon} alt="discord-icon" />
                <p>Discord</p>
            </Link>
            <Link className="socials-grid-item" to={'https://github.com/exp003'} target="_blank">
                <img src={GithubIcon} alt="github-icon" />
                <p>GitHub</p>
            </Link>
        </div>
        <Link className='download-resume-btn' to={"https://www.dropbox.com/sh/gmmwkrgefsxai9v/AAAR4by7bOliqx0OuDhiZ2p6a?dl=0"} target="_blank">Download Resume</Link>
    </div>
  )
}
