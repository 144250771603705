import React from 'react'
import { motion } from 'framer-motion'
import { Link } from 'react-router-dom'

import { SocialsBox } from '../../components/socialsBox/SocialsBox'
import { Clock } from '../../components/clock/Clock'

import './home.css'
import BioBannerImg from '../../assets/gifs/trading-card.gif'

export const Home = () => {
  
  return (
    <motion.div className="home-container"
    initial={{opacity: 0, y: 500 }}
    animate={{opacity: 1, y: 0 }}
    transition={{duration: .6, delay: .2 }}
    exit={{opacity: 0, y: -500 }}
    >
      <div className="home-welcome-banner-container">
        <h1>Welcome...</h1>
        <Clock />
      </div>
      <div className="home-bio-banner-container">
        <div className="home-bio-banner-left">
          <img src={BioBannerImg} alt="" />
          <p>Cole Michael</p>
        </div>
        <div className="home-bio-banner-right">
          <div className="sideways-text">
           1999
          </div>
        </div>
      </div>
      
      <p className='homepage-paragraph'>
      As a multi-platform Creative Specialist, I bring a diverse range of skills and expertise to
       any project. With a passion for photography, videography, editing, illustration, web development, 
       and much more, I excel at bringing unique visions to life. My portfolio reflects my versatility
       and ability to adapt to any project, from creating compelling visual content to designing and 
       developing engaging websites. I thrive on personal and meaningful projects, using my knowledge, 
       skills, and creativity to deliver unique and memorable results.  
       <Link to={'https://www.dropbox.com/sh/gmmwkrgefsxai9v/AAAR4by7bOliqx0OuDhiZ2p6a?dl=0'} target='_blank'> Download my resume </Link>
       and send me an  <Link to={'/contact'}> email </Link>  for any inquiries.
      </p>
      <SocialsBox />
      <div className="blankspace"></div>
    </motion.div>
  )
}
