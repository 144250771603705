import React from 'react'
import { useRef } from 'react';
import emailjs from '@emailjs/browser';
import { motion } from 'framer-motion';

import './contact.css'

export const Contact = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_basn00j', 'template_r1ytjsg', form.current, 'q8ogSanLdF1wivRSu')
      .then((result) => {
          console.log(result.text);
          form.current.reset();
          alert('Message sent')
      }, (error) => {
          console.log(error.text);
      });
  };

  return (
    <motion.div className="contact-page-wrapper"
    initial={{opacity: 0, y: 500 }}
    animate={{opacity: 1, y: 0 }}
    transition={{duration: .6, delay: .2 }}
    exit={{opacity: 0, y: -500 }}
    >
      <h1>Contact Me</h1>
      <form ref={form} onSubmit={sendEmail} className='contact-form'>
        <label>Name</label>
        <input type="text" name="user_name" required/>
        <label>Return Email</label>
        <input type="email" name="user_email" required/>
        <label>Message</label>
        <textarea name="message" />
        <button type="submit">Send</button>
      </form>
    </motion.div>
  );
};
