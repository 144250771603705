import './App.css';
import { Routes, Route, useLocation } from 'react-router-dom';

import { Navbar } from './components/navbar/Navbar';
import { Footer } from './components/footer/Footer';
import { Home } from './pages/home/Home'
import { Photos } from './pages/photos/Photos';
import { Contact } from './pages/contact/Contact';
import { AnimatePresence } from 'framer-motion';

function App() {
  const location = useLocation();
  

  return (
    <div className="App">
      <div className="vignette"></div>
      <div className="scanlines"></div>
      <Navbar location={location}/>
      <AnimatePresence mode='wait'>
        <Routes key={location.pathname} location={location}>
          <Route path='/' element={<Home/>}/>
          <Route path='/contact' element={<Contact />}/>
          <Route path='/photos' element={<Photos />}/>
        </Routes>
      </AnimatePresence>
      <Footer />
    </div>
  );
}

export default App;
