import React from 'react'
import { motion } from 'framer-motion'
import { Link } from 'react-router-dom'

import './menuModal.css'
import XButton from '../../assets/icons/close-icon.png'
// import FolderIcon from '../../assets/icons/directory_closed-4.png'
import HomeIcon from '../../assets/icons/mouse-5.png'
import ContactIcon from '../../assets/icons/envelope_closed-0.png'
import PhotosIcon from '../../assets/icons/msn3-5.png'

export const MenuModal = (props) => {

  return (
      <motion.div className='menu-modal-container'
      initial={{scale: 0, x:-200, y:300, opacity:0}}
      animate={{scale: 1, x:0, y:0, opacity:1}}
      exit={{scale: 0, x:-200, y:300, opacity:0 }}
      transition={{duration: .2}}

      >
          <div className="menu-modal-box">
              <div className="menu-modal-navbar">
                  <p>Navigation...</p>
                  <img src={XButton} alt="x-btn" onClick={() => {props.isOpen(false)}}/>
              </div>
              <div className="menu-modal-grid">
                <Link className='menu-modal-link' to={'/'} onClick={() => {props.isOpen(false)}}>
                    <img src={HomeIcon} alt="folder-icon" />
                    <p>Home</p>
                </Link>
                <Link className='menu-modal-link' to={'/contact'} onClick={() => {props.isOpen(false)}}>
                    <img src={ContactIcon} alt="folder-icon" />
                    <p>Contact</p>
                </Link>
                <Link className='menu-modal-link' to={'/photos'} onClick={() => {props.isOpen(false)}}>
                    <img src={PhotosIcon} alt="folder-icon" />
                    <p>Photos</p>
                </Link>
              </div>
          </div>
      </motion.div>
  )
}
