import React from 'react'
import { motion } from 'framer-motion'

import ColeHeadless from '../../assets/favorites/2019ColeHeadless.jpg'
import FlameDice from '../../assets/favorites/2019FlameDice.jpg'
import FlamingHands from '../../assets/favorites/2019FlamingHands.jpg'
import LilyArm from '../../assets/favorites/2019LilyArm.jpg'
import ColeSkull from '../../assets/favorites/2021ColeSkull.jpg'
import GlassShinBones from '../../assets/favorites/2021GlassShinBones.jpg'
import HorseSkull from '../../assets/favorites/2021HorseSkull.jpg'
import ShinBones from '../../assets/favorites/2021ShinBones.jpg'

import RickyHill from '../../assets/favorites/RockyHill.png'
import BalloonHead from '../../assets/favorites/balloonHead.png'
import BearHead from '../../assets/favorites/bearHead.png'
import BendyHead from '../../assets/favorites/bendyHead.png'
import BrainFood from '../../assets/favorites/brainfood.png'
import BuffArm from '../../assets/favorites/buffArm.png'
import CatMask from '../../assets/favorites/catMask.png'
import EyeHand from '../../assets/favorites/eyeHand.png'
import Headless from '../../assets/favorites/headless.png'
import KaitlynMochi from '../../assets/favorites/kaitlynMochi.png'
import LightTunnel from '../../assets/favorites/lightTunnel.png'
import MicMouth from '../../assets/favorites/micMouth.png'
import NewsPaper from '../../assets/favorites/newsPaper.png'
import NoFace from '../../assets/favorites/noFace.png'
import SkyCity from '../../assets/favorites/skyCity.png'
import TacoPortrait from '../../assets/favorites/tacoPortrait.jpg'
import TentacleHands from '../../assets/favorites/tentacleHands.png'
import TentacleHorns from '../../assets/favorites/tentacleHorns.png'
import Underwater from '../../assets/favorites/underwater.png'
import WebbyPhone from '../../assets/favorites/webbyPhone.png'


import './photos.css'

export const Photos = () => {
  return (
    <motion.div className="photos-page-container"
    initial={{opacity: 0, y: 500 }}
    animate={{opacity: 1, y: 0 }}
    transition={{duration: .6, delay: .2 }}
    exit={{opacity: 0, y: -500 }}
    >
        <img src={RickyHill} alt="" />
        <img src={MicMouth} alt="" />
        <img src={LilyArm} alt="" />
        <img src={BrainFood} alt="" />
        <img src={BalloonHead} alt="" />
        <img src={FlameDice} alt="" />
        <img src={NoFace} alt="" />
        <img src={ColeSkull} alt="" />
        <img src={LightTunnel} alt="" />
        <img src={GlassShinBones} alt="" />
        <img src={HorseSkull} alt="" />
        <img src={ShinBones} alt="" />
        <img src={BearHead} alt="" />
        <img src={BendyHead} alt="" />
        <img src={TacoPortrait} alt="" />
        <img src={BuffArm} alt="" />
        <img src={CatMask} alt="" />
        <img src={FlamingHands} alt="" />
        <img src={ColeHeadless} alt="" />
        <img src={EyeHand} alt="" />
        <img src={Headless} alt="" />
        <img src={KaitlynMochi} alt="" />
        <img src={NewsPaper} alt="" />
        <img src={SkyCity} alt="" />
        <img src={TentacleHands} alt="" />
        <img src={TentacleHorns} alt="" />
        <img src={Underwater} alt="" />
        <img src={WebbyPhone} alt="" />
    </motion.div>
  )
}
