import React from 'react'
import { useState, useEffect } from 'react'

import './clock.css'

export const Clock = () => {
    const [time, setTime] = useState(new Date());

    useEffect(() => {
      const intervalID = setInterval(() => {
        setTime(new Date());
      }, 1000);
      return () => clearInterval(intervalID);
    }, []);
  return (
    <div className='clock-container'>
      <h1>{time.toLocaleTimeString()}</h1>
    </div>
  )
}
